<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Provisionamento / Aguardando ativação</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>

        <div class="mb-3">
          <button v-on:click="atualizarHandle()" class="btn btn-primary">
            Atualizar
          </button>
        </div>
      </div>

      <div class="d-flex">
        <!-- desktop -->
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>OLT</th>
              <th>Porta</th>
              <th>Número de série</th>
              <th>Tempo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="onu in onuDiscoveryList" :key="onu.id" v-on:click="handleOnuRowClick(onu)" :class="{'text-warning': (onu.startRegisterAt), 'text-info cursor-pointer': (!onu.startRegisterAt)}">
              <td scope="row">{{oltDescription(onu.oltId)}}</td>
              <td>{{onu.chassi}}/{{onu.slot}}/{{onu.ponPort}}<span v-if="(onu.index)">:{{onu.index}}</span></td>
              <td>
                {{onu.serialNumber}}
                <span v-if="(onu.startRegisterAt)" class="badge bg-warning ms-1 blinking">
                  Registrando
                </span>
              </td>
              <td>{{ datetime.since(godatetime.toDateTime(onu.createdAt)) }}</td>
            </tr>
          </tbody>
        </table>
        <!-- desktop -->
      </div>
    </div>

    <!-- modal-onu-register -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-onu-register" id="btn-modal-onu-register-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-onu-register" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div v-if="(modalOnuRegister.onu)" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'level-up-alt']" fixed-width/>
              Registrar ONU
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p class="mb-0"><span class="fw-bold">OLT:</span> {{oltDescription(modalOnuRegister.onu.oltId)}}</p>
                <p class="mb-0">
                  <span class="fw-bold">PON:</span>
                  {{modalOnuRegister.onu.chassi}}/{{modalOnuRegister.onu.slot}}/{{modalOnuRegister.onu.ponPort}}:{{modalOnuRegister.onu.index}}
                </p>
                <p class="mb-0"><span class="fw-bold">Serial number:</span> {{modalOnuRegister.onu.serialNumber}}</p>
              </div>

              <div class="col-12">
                <hr class="my-1">
              </div>

              <div class="col-12 col-md-4 mb-2">
                <label class="required----">Instalador</label>
                <select v-model="modalOnuRegister.form.instalador" class="form-select" disabled>
                  <option disabled :value="undefined">Selecione</option>
                </select>
              </div>
              <div class="mb-2 col-12 col-md-4">
                <label class="required">Identificação</label>
                <input type="text" v-model="modalOnuRegister.form.description" class="form-control">
              </div>

              <div class="mb-2 col-12 col-md-4">
                <label class="required w-100">
                  Tipo da ONU
                  <label v-bind:class="cssCheckboxModeloAuto(modalOnuRegister.onu)" class="custom-control custom-checkbox float-end">
                    <input type="checkbox" v-bind:disabled="!canCheckAuto(modalOnuRegister.onu)" v-model="modalOnuRegister.form.useAuto" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description ms-1">Automático</span>
                    <Tooltip v-if="!modalOnuRegister.onu.modelo_auto_id" position="left" :tooltipText="'Contate o suporte para homologação'" class="d-inline d-sm-none">
                      <span class="text-warning cursor-pointer ms-1">
                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" fixed-width/>
                      </span>
                    </Tooltip>
                    <Tooltip v-if="!modalOnuRegister.onu.modelo_auto_id" position="right" :tooltipText="'Contate o suporte para homologação'" class="d-none d-sm-inline">
                      <span class="text-warning cursor-pointer ms-1">
                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" fixed-width/>
                      </span>
                    </Tooltip>
                  </label>
                </label>
                <select v-model="modalOnuRegister.form.onuType"
                    v-bind:disabled="!podeEscolherModelo(modalOnuRegister.onu)"
                    class="form-select">
                  <option disabled :value="undefined">Selecione</option>
                  <option v-for="perfil in modalOnuRegister.onuTypes" :key="perfil.id" :value="perfil.oltInternalName">
                    {{perfil.oltInternalName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-onu-register-fecha">Cancelar</button>
            <button type="button"
                v-on:click="modalOnuRegisterDoRegisterHandle()"
                v-bind:disabled="!modalOnuRegisterCanRegister()"
                class="btn btn-success">
              Registrar e validar sinal
              <font-awesome-icon :icon="['fas', 'forward']" fixed-width/>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-onu-register -->

    <IspOnuApprove :onuId="approveOnuId" :closeFunc="approveOnuClosed" />
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import Tooltip from "@/components/Tooltip.vue";
import datetime from '@/modules/datetime'
import godatetime from '@/modules/godatetime'
import IspOnuApprove from "@/components/IspOnuApprove.vue";

export default {
  name: 'ProvisionamentoOnus',

  components: {
    Tooltip,
    IspOnuApprove,
  },

  data() {
    return {
      oltList: [],
      onuDiscoveryList: undefined,
      approveOnuId: undefined,
      interval: {
        onuRefresh: undefined,
        initOltList: undefined,
      },
      modalOnuRegister: {
        onu: undefined,
        onuTypes: [],
        form: {
          description: '',
          onuType: '',
          useAuto: false,
        },
      },
    }
  },

  methods: {
    loadOltList() {
      api.get(`/isp/olts?paginate.cancel`).then(res => {
        this.oltList = res.data.items;
        clearInterval(this.interval.initOltList);
      });
    },

    refreshOnuList() {
      const updateLoading = (this.onuDiscoveryList === undefined);

      if (updateLoading) {
        loading(this.onuDiscoveryList === undefined);
      }
      api.get(`/isp/onu-discovery?orderBy[created_at]=DESC&paginate.cancel`).then(res => {
        if (updateLoading) {
          loading(false);
        }
        this.applyOnuList(res.data.items)
      }).catch(() => {
        if (updateLoading) {
          loading(false);
        }
      });
    },

    applyOnuList(onuDiscoveryList) {
      if (this.onuDiscoveryList === undefined) {
        this.onuDiscoveryList = onuDiscoveryList;
        return;
      }

      // add new ones on top of the list
      let ids = [];
      for (let i = 0; i < onuDiscoveryList.length; i++) {
        const newOnu = onuDiscoveryList[i];
        let found = false;
        ids.push(newOnu.id);

        for (let j = 0; j < this.onuDiscoveryList.length; j++) {
          const existent = this.onuDiscoveryList[j];

          if (existent.id === newOnu.id) {
            this.onuDiscoveryList[j] = newOnu;
            found = true;
            this.$forceUpdate();

            break;
          }
        }

        if (!found) {
          this.onuDiscoveryList.unshift(newOnu);
        }
      }

      // remove expired onus
      for (let i = this.onuDiscoveryList.length-1; i >= 0 ; i--) {
        const element = this.onuDiscoveryList[i];
        let found = false;

        for (let j = 0; j < ids.length; j++) {
          if (element.id == ids[j]) {
            found = true;
            break;
          }
        }

        if (!found) {
          // TODO: VERIFICAR SE A ONU QUE ESTÁ REMOVENDO É A EM EDIÇÃO NO MODAL, SE FOR, DEVE CANCELÁ-LO OU MOSTRAR MENSAGEM QUE ELA NÃO SE ENCONTRA MAIS NA LISTA
          this.onuDiscoveryList.splice(i, 1);
        }
      }
    },

    atualizarHandle() {
      this.refreshOnuList();
    },

    oltDescription(id) {
      if (!id) {
        return undefined;
      }

      const olt = this.oltList.find(x => (x.id === id));
      if (!olt) {
        return '-';
      }

      return olt.description;
    },

    cssCheckboxModeloAuto(onu) {
      if (this.canCheckAuto(onu)) {
        return ['cursor-pointer'];
      }

      return ['text-muted-2'];
    },

    canCheckAuto(onu) {
      switch (true) {
        case (!onu.suggestedType):
          return false;
      }

      return true;
    },

    podeEscolherModelo() {
      switch (true) {
        case this.modalOnuRegister.form.useAuto:
          return false;
      }

      return true;
    },

    handleOnuRowClick(onu) {
      if (onu.startRegisterAt) {
        return;
      }

      this.modalOnuRegisterOpen(onu);
    },

    /////////////////////////////////////
    // modal-onu-register
    modalOnuRegisterOpen(onu) {
      this.modalOnuRegisterReset();

      this.modalOnuRegister.onu = clone(onu);
      this.modalOnuRegister.form.useAuto = false;
      if (onu.suggestedType) {
        this.modalOnuRegister.form.useAuto = true;
      }

      this.modalOnuRegisterAbre();
      this.modalOnuRegisterLoadOltOnuTypes();
    },

    modalOnuRegisterAbre() {
      document.getElementById('btn-modal-onu-register-abre').click();
    },

    modalOnuRegisterFecha() {
      document.getElementById('btn-modal-onu-register-fecha').click();
    },

    modalOnuRegisterReset() {
      this.modalOnuRegister.onu = undefined;
      this.modalOnuRegister.form.description = '';
      this.modalOnuRegister.form.useAuto = false;
      this.modalOnuRegister.form.onuType = undefined;
    },

    modalOnuRegisterLoadOltOnuTypes() {
      const oltID = this.modalOnuRegister.onu.oltId;
      const apiUrl = `/isp/olts/${oltID}/onu-types?paginate.cancel`;

      loading(true);
      api.get(apiUrl).then(res => {
        loading(false);
        this.modalOnuRegister.onuTypes = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    modalOnuRegisterCanRegister() {
      const form = this.modalOnuRegister.form;

      switch (true) {
        case (!form.description):
        case (!form.useAuto && !form.onuType):
          return false;

        default:
          return true;
      }
    },

    modalOnuRegisterDoRegisterHandle() {
      const sendData = {
        oltId: this.modalOnuRegister.onu.oltId,
        onuDiscoveryId: this.modalOnuRegister.onu.id,
        description: this.modalOnuRegister.form.description,
        onuType: this.modalOnuRegister.form.onuType,
      };

      loading(true);
      api.post(`/isp/onu-discovery/register`, sendData).then(res => {
        loading(false);
        this.refreshOnuList();
        this.modalOnuRegisterFecha();
        this.approveOnuId = res.data.id
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
    // modal-onu-register
    /////////////////////////////////////

    /////////////////////////////////////
    // modal-onu-info
    async approveOnuClosed() {
      this.approveOnuId = undefined;
    },
    // modal-onu-info
    /////////////////////////////////////

  },
  computed: {
    datetime() {
      return datetime;
    },
    godatetime() {
      return godatetime;
    },
  },
  created() {
    this.interval.onuRefresh = setInterval(this.refreshOnuList, 3000);
    this.interval.initOltList = setInterval(this.loadOltList, 3000);
    this.loadOltList();
    this.refreshOnuList();
  },
  destroyed() {
    clearInterval(this.interval.onuRefresh);
    clearInterval(this.interval.initOltList);
  },
}
</script>
